import { createRouter, createWebHistory } from 'vue-router';
import { LoginCallback, navigationGuard } from '@okta/okta-vue';
import HomeView from '../views/HomeView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('../views/PrivacyView.vue')
  },
  {
    path: '/about_us',
    name: 'about_us',
    component: () => import('../views/AboutUsView.vue')
  },
  {
    path: '/custom_tutoring',
    name: 'custom_tutoring',
    component: () => import('../views/CustomTutoringView.vue')
  },
  {
    path: '/calculus1',
    name: 'calculus1',
    component: () => import('../views/Calculus1View.vue')
  },
  {
    path: '/latin1',
    name: 'latin1',
    component: () => import('../views/BeginningLatinView.vue')
  },
  {
    path: '/prealgebra',
    name: 'prealgebra',
    component: () => import('../views/PreAlgebraView.vue')
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: () => import('../views/PricingView.vue')
  },
  {
    path: '/summer_school',
    name: 'summer_school',
    component: () => import('../views/SummerSchoolView.vue')
  },
  {
    path: '/trumpet101',
    name: 'trumpet101',
    component: () => import('../views/Trumpet101View.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notfound',
    component: () => import('../views/NotFoundPageView.vue'),
  },
  { path: '/callback', component: LoginCallback }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(navigationGuard);

export default router;
