import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, toDisplayString as _toDisplayString, mergeProps as _mergeProps, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, unref as _unref, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/dusty.gif'


const _hoisted_1 = {
  class: "card relative z-2",
  style: {}
}
const _hoisted_2 = ["title"]
const _hoisted_3 = ["href"]
const _hoisted_4 = ["href", "target"]

import Menubar from 'primevue/menubar';
import 'primeicons/primeicons.css';
import { MenuItem } from 'primevue/menuitem';

const __default__ = {
  name: 'app',
  methods: {
    login() {
      this.$auth.signInWithRedirect({ originalUri: '/' })
    },
    async logout() {
      await this.$auth.signOut()
    }
  },
  data() {
    return {
      personId: null,

      privacyItems: [
        {
          label: 'Privacy & Terms of Service',
          route: '/privacy'
        }
      ],

      items: [
        {
          label: 'Home',
          route: '/'
        },
        {
          label: 'Classes',
          items: [
            {
              label: 'Calculus I',
              route: '/calculus1',
            },
            /*
            {
              label: 'Latin 1',
              route: '/latin1',
            },
            */
            {
              label: 'Pre-Algebra',
              route: '/prealgebra'
            },
            /*
            {
              label: 'Pre-Algebra',
              items: [
                {
                  label: 'Pre-Algebra 101-1: Integers & Intro to Equations',
                  route: '/prealgebra101'
                },
                {
                  label: 'Pre-Algebra 102-1: Linear Equations',
                  route: '/prealgebra102'
                },
                {
                  label: 'Pre-Algebra 103-1: Exponents',
                  route: '/prealgebra103'
                },
                {
                  label: 'Pre-Algebra 104-1: Rational Numbers',
                  route: '/prealgebra104'
                },
                {
                  label: 'Pre-Algebra 105-1: Ratio, Proportion, and Percent',
                  route: '/prealgebra105'
                },
              ]
            },
          */
            {
              label: 'Trumpet - Beginning',
              route: '/trumpet101'
            },
            {
              separator: true,
            },
            {
              label: 'Custom Tutoring',
              route: '/custom_tutoring'
            },
            {
              label: 'Summer School',
              route: '/summer_school'
            },
          ]
        },
        {
          label: 'Pricing',
          route: '/pricing'
        },
        {
          label: 'About Us',
          route: '/about_us'
        },
      ]
    }
  }
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(Menubar), {
      model: ((
      _ctx.authState?.isAuthenticated ? _ctx.items
        //        .concat((authState?.accessToken.claims.groups as [string]).includes('CommonAccess') ? commonItems : [])
        .concat(_ctx.privacyItems as []) : _ctx.items.concat(_ctx.privacyItems as [])) as MenuItem[])
    }, {
      start: _withCtx(() => _cache[2] || (_cache[2] = [
        _createElementVNode("a", {
          href: "/",
          style: {"margin-right":"1em"}
        }, [
          _createElementVNode("img", {
            alt: "logo",
            src: _imports_0,
            height: "40",
            class: "mr-2"
          })
        ], -1)
      ])),
      end: _withCtx(() => [
        (_ctx.authState?.isAuthenticated)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args))),
              id: "logout",
              class: "pi pi-sign-out",
              title: `Logout ${_ctx.authState.accessToken?.claims.sub}`,
              style: {"display":"none","height":"2.5em","cursor":"pointer"}
            }, " Logout", 8, _hoisted_2))
          : (_openBlock(), _createElementBlock("button", {
              key: 1,
              class: "pi pi-sign-in",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.login && _ctx.login(...args))),
              id: "login",
              style: {"height":"2.5em","cursor":"pointer","display":"none"}
            }, " Login"))
      ]),
      item: _withCtx(({ label, item, props, root }) => [
        (item.route)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: item.route,
              custom: ""
            }, {
              default: _withCtx((routerProps) => [
                _createElementVNode("a", _mergeProps({
                  href: routerProps.href
                }, props.action), [
                  _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.icon)), null, 16),
                  _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.label)), _toDisplayString(label), 17)
                ], 16, _hoisted_3)
              ]),
              _: 2
            }, 1032, ["to"]))
          : (_openBlock(), _createElementBlock("a", _mergeProps({
              key: 1,
              href: item.url,
              target: item.target
            }, props.action), [
              _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.icon)), null, 16),
              _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.label)), _toDisplayString(label), 17),
              _createElementVNode("span", _mergeProps({
                class: [root ? 'pi pi-fw pi-angle-down' : 'pi pi-fw pi-angle-right']
              }, props.submenuicon), null, 16)
            ], 16, _hoisted_4))
      ]),
      _: 1
    }, 8, ["model"]),
    _createVNode(_component_router_view),
    _cache[3] || (_cache[3] = _createElementVNode("div", { style: {"max-width":"100em","width":"95%"} }, [
      _createElementVNode("footer", null, [
        _createElementVNode("br"),
        _createElementVNode("br"),
        _createElementVNode("hr"),
        _createElementVNode("i", null, "Providing a quality, personalized education to those who are academically able but inadequately served by the public school system")
      ])
    ], -1))
  ]))
}
}

})