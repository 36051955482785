import { createApp } from 'vue';
import App from './App.vue';
import './main.css'
import router from './router';
import { OktaAuth } from '@okta/okta-auth-js';
import OktaVue from '@okta/okta-vue';
import PrimeVue from 'primevue/config';
import Aura from "@primevue/themes/aura";

const config = {
  issuer: 'https://dev-907733.okta.com/oauth2/default',
  redirectUri: window.location.origin + '/callback',
  clientId: '0oaeid6yumGMQwtop4x7',
  scopes: ['openid', 'profile', 'email']
};

const oktaAuth = new OktaAuth(config);

createApp(App)
  .use(router)
  .use(OktaVue, { oktaAuth })
  .use(PrimeVue, {
    theme: {
      preset: Aura,
      options: {
        prefix: 'p',
        darkModeSelector: 'hidden',
        cssLayer: false
      }
    }
  })
  .mount('#app');
