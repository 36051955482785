<template>
  <div class="card relative z-2" style="">
    <Menubar :model="((
      authState?.isAuthenticated ? items
        //        .concat((authState?.accessToken.claims.groups as [string]).includes('CommonAccess') ? commonItems : [])
        .concat(privacyItems as []) : items.concat(privacyItems as [])) as MenuItem[])">
      <template #start>
        <a href="/" style="margin-right:1em;"><img alt="logo" src="@/assets/dusty.gif" height="40" class="mr-2" /></a>
      </template>
      <template #end>
        <button v-if="authState?.isAuthenticated" v-on:click="logout" id="logout" class="pi pi-sign-out"
          :title="`Logout ${authState.accessToken?.claims.sub}`"
          style="display: none; height:2.5em;cursor:pointer">&nbsp;Logout</button>
        <button class="pi pi-sign-in" v-else v-on:click="login" id="login"
          style="height:2.5em;cursor:pointer;display: none;">&nbsp;Login</button>
      </template>
      <template #item="{ label, item, props, root }">
        <router-link v-if="item.route" v-slot="routerProps" :to="item.route" custom>
          <a :href="routerProps.href" v-bind="props.action">
            <span v-bind="props.icon" />
            <span v-bind="props.label">{{ label }}</span>
          </a>
        </router-link>
        <a v-else :href="item.url" :target="item.target" v-bind="props.action">
          <span v-bind="props.icon" />
          <span v-bind="props.label">{{ label }}</span>
          <span :class="[root ? 'pi pi-fw pi-angle-down' : 'pi pi-fw pi-angle-right']" v-bind="props.submenuicon" />
        </a>
      </template>
    </Menubar>
    
      <router-view />
      <div style="max-width: 100em;width:95%;">
      <footer>
        <br><br>
        <hr>
        <i>Providing a quality, personalized education to those who are academically able but inadequately served by
          the
          public school system</i>

      </footer>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'app',
  methods: {
    login() {
      this.$auth.signInWithRedirect({ originalUri: '/' })
    },
    async logout() {
      await this.$auth.signOut()
    }
  },
  data() {
    return {
      personId: null,

      privacyItems: [
        {
          label: 'Privacy & Terms of Service',
          route: '/privacy'
        }
      ],

      items: [
        {
          label: 'Home',
          route: '/'
        },
        {
          label: 'Classes',
          items: [
            {
              label: 'Calculus I',
              route: '/calculus1',
            },
            /*
            {
              label: 'Latin 1',
              route: '/latin1',
            },
            */
            {
              label: 'Pre-Algebra',
              route: '/prealgebra'
            },
            /*
            {
              label: 'Pre-Algebra',
              items: [
                {
                  label: 'Pre-Algebra 101-1: Integers & Intro to Equations',
                  route: '/prealgebra101'
                },
                {
                  label: 'Pre-Algebra 102-1: Linear Equations',
                  route: '/prealgebra102'
                },
                {
                  label: 'Pre-Algebra 103-1: Exponents',
                  route: '/prealgebra103'
                },
                {
                  label: 'Pre-Algebra 104-1: Rational Numbers',
                  route: '/prealgebra104'
                },
                {
                  label: 'Pre-Algebra 105-1: Ratio, Proportion, and Percent',
                  route: '/prealgebra105'
                },
              ]
            },
          */
            {
              label: 'Trumpet - Beginning',
              route: '/trumpet101'
            },
            {
              separator: true,
            },
            {
              label: 'Custom Tutoring',
              route: '/custom_tutoring'
            },
            {
              label: 'Summer School',
              route: '/summer_school'
            },
          ]
        },
        {
          label: 'Pricing',
          route: '/pricing'
        },
        {
          label: 'About Us',
          route: '/about_us'
        },
      ]
    }
  }
}
</script>

<script lang="ts" setup>
import Menubar from 'primevue/menubar';
import 'primeicons/primeicons.css';
import { MenuItem } from 'primevue/menuitem';
</script>
